.alerts {
    z-index: 100;
    position: fixed;
    left: 50%;
    left: 50%;
    transform: translate(-50%, 0);
}

.alert {
    padding-right: 25px;
    border: 1px solid #efefef;
    box-shadow: 0 0 10px #aaa;
    
    .close {
        position: relative;
        left: 10px;
    }
}