.signForm {
    position: relative;
    width: 500px;
    margin: 30px auto;
    padding: 2%;
    border: 1px solid #666;

    h4 {
        font-size: 22px;
        margin-bottom: 10px;
    }

    input {
        display:block;
        width:100%;
        padding:14px;
        border: 1px solid #666;
        border-radius:6px;
        margin-bottom:12px;
        color:#7f8c8d;
        font-size:16px;

        &:not(.button):focus {
            background:#fafafa;
            outline-color: #c60000;
        }
    }

    a, a:visited {
        text-decoration:none;
        font-weight:400;
        text-shadow:1px 1px 0px white;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }

    .button {
        position:relative;
        float:left;
        width:145px;
        margin-top:10px;
        border: none;
        background:$drmax-purple;
        color:#fff;
        font-weight:400;
        text-shadow:1px 1px 0px #b10000;
        box-shadow:0px 3px 0px #b10000;

        &:active {
            outline-width: 0;
            box-shadow:0px 3px 0px #7f002f;
        }
    }

    .remember {
        text-align: right;
        margin-top: 20px;

        label {
            font-weight: normal;
        }

        input {
            width: auto;
            float: right;
            margin-left: 5px;
            margin-top: 6px;
        }
    }

    li {
        text-align: right;
        list-style:none;
    }

    .error li {
        text-align: center;
        color: red;
    }

    .back {
        position: absolute;
        bottom: 40px;
        right: 40px;
    }

    .note, .instruction {
        margin-bottom: 15px;
    }

    .note {
        background: #efefef;
        padding: 10px;
    }
}

