select.has-error + span.select2 {
    span.select2-selection__rendered {
        background-color: $brand-danger;
        color: #fff;
    }

    &.select2-container--open .select2-selection--single .select2-selection__arrow b {
        border-bottom-color: #fff;
    }

    .select2-selection--single .select2-selection__arrow b {
        border-top-color: #fff;
    }
}

.help-block.text-danger {
    color: $brand-danger;
    float: right;
    margin-left: 5px;
}

.editor {
    margin-bottom: 20px;
}

#app .select2-selection__rendered {
    height: auto;
    padding: 12px 16px;
    font-size: 14px;
}

#app .select2-selection--multiple, #app .select2-selection--single {
    border: none;
}

