.product-list {
    margin: 0;
}

$product-thumb-height: 220px;

$break-large: 1140px;
$break-small: 750px;
$margin: 4.3333%;

.product-item {
    display: inline-block;
    vertical-align: top;
    margin-left: $margin;
    margin-bottom: $margin;
    min-width: 326px;
    width: 30%;
    height: 26em;
    border: 1px solid rgb(223, 223, 223);
    position: relative;
    text-align:center;

    &:nth-child(2n+1) {
        margin-left: 0;
    }

    @media screen and (min-width: $break-large) {
        &:nth-child(2n+1) {
            margin-left: $margin;
        }
        &:nth-child(3n+1) {
            margin-left: 0;
        }
    }

    @media screen and (max-width: $break-small) {
        margin-left: 0;
    }

    a.detail-link {
        padding: 4% 2% 2%;
        display: block;
        text-decoration: none;

        h3 {
            height: 2.1em;
            margin: 4% 0 2%;
            font-size: 1.3em;
            color: #999999;
        }

        &:hover h3 {
            color: #000;
            text-decoration: none;
        }

        .img-box {
            height: $product-thumb-height;
            white-space: nowrap;
            text-align: center;

            &:before{
                content: "";
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }

            img {
                margin: 0 auto;
                display: inline-block;
                max-width: 300px;
                max-height: $product-thumb-height;
                width: auto;
                height: auto;
                vertical-align: middle;
                box-shadow: 0px 0px 10px #ddd;
            }
        }
        
        .product-price {
            font-size: 1.4em;
        }
    }
}

.b_btm{
    position: absolute;
    width: 100%;
    border-bottom: 6px solid $drmax-purple;
    left: 0px;
    display: block;
    bottom: 0px;
}

$counter-height: 42px;

.counter {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;

    .plus, .minus {
        position: absolute;
        display: block;
        width: 20px;
        height: 21px;
        line-height: 22px;
        left: 0;
        text-align: center;
        font-size: 0.7em;
        font-weight: 600;
        color: #999999;
        border: 1px solid #e4e4e4;

        &:hover {
            text-decoration: none;
        }
    }

    .plus {
        top: 0;
        border-bottom: 0;
    }

    .minus {
        top: 21px;
        border-top: 0;
    }

    input[type=text],
    input[type=number] {
        display: inline-block;
        vertical-align: top;
        width: 45px;
        height: $counter-height;
        margin-left: 20px;
        text-align: center;
        border: 1px solid #e4e4e4;
        border-left-width: 0px;
        border-right-width: 0px;
    }

    .measure {
        display: inline-block;
        height: $counter-height;
        line-height: $counter-height;
        border: 1px solid #e4e4e4;
        border-left-width: 0px;
        border-right-width: 0px;
        padding: 0 7px;
    }
}

.buy {
    display: inline-block;
    vertical-align: top;
    height: $counter-height;
    box-sizing: border-box;
    margin: 0 auto 0px;
    padding: 10px 20px;
    border: none;
    background: $drmax-purple;
    color: #ffffff;
    font-size: 1.2em;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #ffffff;
        text-decoration: none;
    }
}

.search {
    float: left;
    margin-bottom: 10px;

    @media screen and (max-width: $screen-sm) {
        float: none;
    }
}

.sort {
    text-align: right;
    margin-bottom: 20px;

    @media screen and (max-width: $screen-sm) {
        text-align: left;
    }
}

.subcategories {
    margin-bottom: 2%;

    li {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 20px;
        background-color: $drmax-darker-gray;

        a {
            display: block;
            padding: 10px 20px;
            color: #555;
            border: 1px solid #ccc;
            border-radius: 5px;

            &:hover, &:active {
                text-decoration: none;
            }
        }

        &:hover {
            background-color: #d4d4d4;
        }
    }
}

#load-more-div {
    text-align: center;
}
