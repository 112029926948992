/*
* 	Owl Carousel Owl Demo Theme 
*	v1.24
*/
#owl-demo .item{
    margin: 0.5em;
    padding: 1em;
    background: #fff;
    cursor:pointer;
}
#owl-demo .item:hover a{
    color:#171717;
}
#owl-demo .item img{
    display: block;
    height: auto;
}
.item img:hover,.item a:hover,.item p:hover{
    opacity:1;
    text-decoration: none;
}
.owl-theme .owl-controls{
    margin-top: 10px;
    text-align: center;
}
/*-----*/
.item h4 a{
    font-size: 1.8em;
    color: #999999;
    padding: 0.5em 0 0.4em 0;
    display:block;
    text-decoration: none;
}
.item a.btn,.item a.btn:hover{
    font-size: 1.2em;
    background: $drmax-green;
    display: inline-block;
    padding: 10px 20px;
    color: #ffffff;
}
/*---//--*/
/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
    color: #FFF;
    display: inline-block;
    zoom: 1;
    *display: inline;/*IE7 life-saver */
    margin: 5px;
    height:85px;
    width:34px;
    display:block;
    font-size: 12px;
    transition:0.5s all;
    -webkit-transition:0.5s all;
    -moz-transition:0.5s all;
    -o-transition:0.5s all;
}
.owl-theme .owl-controls .owl-buttons div:hover{
    opacity:0.6;
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
    text-decoration: none;
}
.owl-prev {
    position: absolute;
    top: 23%;
    left: -5%;
    background: url(../images/c-arrows.png) no-repeat;
}
.owl-next{
    position: absolute;
    top:23%;
    right: -5%;
    background: url(../images/c-arrows.png) no-repeat -39px 0px;
}
/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
    display: inline-block;
    zoom: 1;
    *display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span{
    display: block;
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    filter: Alpha(Opacity=50);/*IE7 fix*/
    opacity: 0.5;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    background: url(../images/c-arrows.png) no-repeat;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
    filter: Alpha(Opacity=100);/*IE7 fix*/
    opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
    height: auto;
    width: auto;
    color: #FFF;
    padding: 2px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
    min-height: 150px;
}
/* 
 * 	Core Owl Carousel CSS File
 *	v1.24
 */
/* clearfix */
.owl-carousel .owl-wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
/* display none until init */
.owl-carousel{
    display: none;
    position: relative;
    width: 100%;
    -ms-touch-action: pan-y;
    margin: 2% 0 4%;
}
.owl-carousel .owl-wrapper{
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer{
    overflow: hidden;
    position: relative;
    width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight{
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
}
.owl-carousel .owl-item{
    float: left;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div{
    cursor: pointer;
}
.owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing { 
    cursor:url(grabbing.png) 8 8, move;
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
}

/* CSS3 Transitions */

.owl-origin {
    -webkit-perspective: 1200px;
    -webkit-perspective-origin-x : 50%;
    -webkit-perspective-origin-y : 50%;
    -moz-perspective : 1200px;
    -moz-perspective-origin-x : 50%;
    -moz-perspective-origin-y : 50%;
    perspective : 1200px;
}
/* fade */
.owl-fade-out {
    z-index: 10;
    -webkit-animation: fadeOut .7s both ease;
    -moz-animation: fadeOut .7s both ease;
    animation: fadeOut .7s both ease;
}
.owl-fade-in {
    -webkit-animation: fadeIn .7s both ease;
    -moz-animation: fadeIn .7s both ease;
    animation: fadeIn .7s both ease;
}
/* backSlide */
.owl-backSlide-out {
    -webkit-animation: backSlideOut 1s both ease;
    -moz-animation: backSlideOut 1s both ease;
    animation: backSlideOut 1s both ease;
}
.owl-backSlide-in {
    -webkit-animation: backSlideIn 1s both ease;
    -moz-animation: backSlideIn 1s both ease;
    animation: backSlideIn 1s both ease;
}
/* goDown */
.owl-goDown-out {
    -webkit-animation: scaleToFade .7s ease both;
    -moz-animation: scaleToFade .7s ease both;
    animation: scaleToFade .7s ease both;
}
.owl-goDown-in {
    -webkit-animation: goDown .6s ease both;
    -moz-animation: goDown .6s ease both;
    animation: goDown .6s ease both;
}
/* scaleUp */
.owl-fadeUp-in {
    -webkit-animation: scaleUpFrom .5s ease both;
    -moz-animation: scaleUpFrom .5s ease both;
    animation: scaleUpFrom .5s ease both;
}

.owl-fadeUp-out {
    -webkit-animation: scaleUpTo .5s ease both;
    -moz-animation: scaleUpTo .5s ease both;
    animation: scaleUpTo .5s ease both;
}
/* Keyframes */
/*empty*/
@-webkit-keyframes empty {
    0% {opacity: 1}
}
@-moz-keyframes empty {
    0% {opacity: 1}
}
@keyframes empty {
    0% {opacity: 1}
}
@-webkit-keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}
@-moz-keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}
@keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:1; }
}
@-webkit-keyframes fadeOut {
    0% { opacity:1; }
    100% { opacity:0; }
}
@-moz-keyframes fadeOut {
    0% { opacity:1; }
    100% { opacity:0; }
}
@keyframes fadeOut {
    0% { opacity:1; }
    100% { opacity:0; }
}
@-webkit-keyframes backSlideOut {
    25% { opacity: .5; -webkit-transform: translateZ(-500px); }
    75% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
    100% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
}
@-moz-keyframes backSlideOut {
    25% { opacity: .5; -moz-transform: translateZ(-500px); }
    75% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
    100% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
}
@keyframes backSlideOut {
    25% { opacity: .5; transform: translateZ(-500px); }
    75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
    100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
}
@-webkit-keyframes backSlideIn {
    0%, 25% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(200%); }
    75% { opacity: .5; -webkit-transform: translateZ(-500px); }
    100% { opacity: 1; -webkit-transform: translateZ(0) translateX(0); }
}
@-moz-keyframes backSlideIn {
    0%, 25% { opacity: .5; -moz-transform: translateZ(-500px) translateX(200%); }
    75% { opacity: .5; -moz-transform: translateZ(-500px); }
    100% { opacity: 1; -moz-transform: translateZ(0) translateX(0); }
}
@keyframes backSlideIn {
    0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
    75% { opacity: .5; transform: translateZ(-500px); }
    100% { opacity: 1; transform: translateZ(0) translateX(0); }
}
@-webkit-keyframes scaleToFade {
    to { opacity: 0; -webkit-transform: scale(.8); }
}
@-moz-keyframes scaleToFade {
    to { opacity: 0; -moz-transform: scale(.8); }
}
@keyframes scaleToFade {
    to { opacity: 0; transform: scale(.8); }
}
@-webkit-keyframes goDown {
    from { -webkit-transform: translateY(-100%); }
}
@-moz-keyframes goDown {
    from { -moz-transform: translateY(-100%); }
}
@keyframes goDown {
    from { transform: translateY(-100%); }
}

@-webkit-keyframes scaleUpFrom {
    from { opacity: 0; -webkit-transform: scale(1.5); }
}
@-moz-keyframes scaleUpFrom {
    from { opacity: 0; -moz-transform: scale(1.5); }
}
@keyframes scaleUpFrom {
    from { opacity: 0; transform: scale(1.5); }
}

@-webkit-keyframes scaleUpTo {
    to { opacity: 0; -webkit-transform: scale(1.5); }
}
@-moz-keyframes scaleUpTo {
    to { opacity: 0; -moz-transform: scale(1.5); }
}
@keyframes scaleUpTo {
    to { opacity: 0; transform: scale(1.5); }
}
/*@media only screen and (max-width:1440px) and (min-width:1366px) {
    .owl-carousel {
        width: 80%;
        margin: 0 auto;
        padding: 2%;
    }
}
@media only screen and (max-width:1366px) and (min-width:1280px) {
    .owl-carousel {
        width: 80%;
        margin: 0 auto;
        padding: 2%;
    }
}*/
@media only screen and (max-width:1280px) and (min-width:1024px) {
    .owl-carousel {
        width: 80%;
        margin: 0 auto;
        padding: 2%;
    }
    .item h4 a{
        font-size: 1.2em;
    }
}
@media only screen and (max-width:1024px) and (min-width:768px) {
    .owl-carousel {
        width: 80%;
        margin: 0 auto;
        padding: 2%;
    }
}
@media only screen and (max-width:800px) and (min-width:640px) {
    .owl-carousel {
        width: 80%;
        margin: 0 auto;
        padding: 2%;
    }
    .item h4 a{
        font-size: 1.2em;
    }
    .da-slide h2 {
        margin-left:10px;
        font-size: 50px;
    }
    .da-slide p {
        margin-left:10px;
        top: 135px;
    }
    .da-slide .da-link {
        margin-left:10px;
        top: 225px;
        padding: 10px 34px 8px;
    }
}
@media only screen and (max-width:640px) and (min-width:480px) {
    .owl-carousel {
        width: 80%;
        margin: 0 auto;
        padding: 2%;
    }
    .da-slide h2 {
        margin-left:10px;
        font-size: 30px;
    }
    .da-slide p {
        margin-left:10px;
        top: 110px;
    }
    .da-slide .da-link {
        margin-left:10px;
        top: 200px;
        padding: 10px 34px 8px;
    }
    .da-slide {
        padding: 0 5%;
        width: 80%;
    }
}
@media only screen and (max-width:480px) and (min-width:320px) {
    .owl-carousel {

    }
    .da-slide {
        padding: 0 2%
    }
    .da-slide h2 {
        top:120px;
        margin-left:10px;
        font-size: 30px;
    }
    .da-slide p {
        margin-left:10px;
        top: 150px;
        font-size: 13px;
    }
    .da-slider {
        min-width: 300px;
    }
    .da-slide .da-link {
        top:240px;
        margin-left:10px;
        padding: 8px 18px 6px;
        font-size:1.2em;
    }
    .da-arrows span.da-arrows-next {
        right: 12px;
    }
}