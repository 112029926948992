.h_icon{
    float: right;
    position: relative;
    top: 15px;
    right: 8px;

    .fa-shopping-cart {
        color: #fff;
        position: absolute;
        top: 2px;
        font-size: 40px;
    }
}
.icon1:before,.icon1:after {
    content: "";
    display: table;
}
.icon1:after {
    clear: both;
}
.icon1 li {
    float: left;
    position: relative;
}
.icon1 li:last-child{
    border-right:none;
}
.icon1 li:hover > a {
    color: #fafafa;
}
.icon1 ul {
    width:330px;
    margin: 20px 0 0 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50px;
    right: 0px;
    z-index: 1;
    background: #ffffff;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0px 2px 6px #777777;
    -webkit-box-shadow: 0px 2px 6px #777777;
    -moz-box-shadow: 0px 2px 6px #777777;
    -o-box-shadow: 0px 2px 6px #777777;
}
.icon1 li:hover > ul {
    opacity: 1;
    visibility: visible;
    margin: 0;
}
.icon1 ul ul {
    top: 6px;
    left: 20px;
    margin: 0 0 0 20px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
}
.icon1 ul li {
    float: none;
    display: block;
    border: 0;
}
.icon1 ul li:first-child{
    border-top:none;
}
.icon1 ul li:first-child a{
    border-top-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    -o-border-top-left-radius: 5px;
}
.icon1 ul li:last-child {   
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;    
}
.icon1 ul a {    
    _height: 10px; /*IE6 only*/
    white-space: nowrap;
    float: none;
    text-transform: none;
}
.active-icon{
    display: block;
}
.icon1-trigger {
    display: none;
}
.icon1 .profile_img {
    margin-top: 3px;
}


.c1 {
    width: 46px;
    height: 46px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 2px;
    
    &:hover {
        text-decoration: none;
    }
}
.c1 i {
    padding-left: 50px;
    font-size: 2em;
    font-style: normal;
    font-weight: 600;
    color: #fff;
}

.sub-icon1 img {
    border:none;
    display:inline-block;
    vertical-align:middle;
}

$cart-product-thumb-width: 80px;
$cart-product-thumb-height: 60px;

.sub-icon1.product-list {
    padding: 0px;
    z-index: 999;

    li {
        &:after {
            content: " ";
            display: block;
            clear: both;
        }

        a {
            display: block;
            height: $cart-product-thumb-height;
            box-sizing: content-box;
            padding: 10px;
            background-color: $drmax-gray;
            text-align:center;
            font-size: 0.8925em;
            color: #555555;
            line-height: 1.8em;

            &:hover {
                background-color: $drmax-darker-gray;
                text-decoration: none;
            }
        }
    }

    h3 {
        text-align:center;
        font-size: 1.3em;
        color: $drmax-purple;
        margin-bottom: 4%;
        padding-top: 10px;
    }

    .cart-product-img  {
        float: left;
        width: $cart-product-thumb-width;
        margin-right: 5px;

        img {
            display: block;
            max-width: $cart-product-thumb-width;
            max-height: $cart-product-thumb-height;
            width: auto;
            height: auto;
        }
    }

    .cart-product-name {
        text-align: left;
        white-space: normal;
    }
}

.pharmacy-select-panel {
    margin-bottom: 20px;
}

.finish-order {
    float: right;
    font-size: 20px;
    padding: 20px;
    margin-top: 20px;
}

.empty-cart {
    text-align: center;
    font-size: 1.6em;
    margin-top: 20px;
}