.nav-tabs > li > a {
    font-size: 14px;
}

.tab-content {
    padding: 20px 30px;
    border: 1px solid #DFD7CA;
    border-top: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}