.left_content {
    position: relative;
    width: 72.3333%;
    float: left;
}
.left_sidebar {
    width: 22.3333%;
    float: left;
    margin-left: 4.33333%;
}
@media only screen and (max-width: 1085px) {
    .left_content {
        width: 100%;
        float: none;
    }
    .left_sidebar {
        width: 100%;
        float: none;
        margin-left: 0%;
    }
}

.product-img-box {
    width: 350px;

    .product-img {
        margin-bottom: 15px;

        img {
            display: block;
            max-width: 350px;
            max-height: 600px;
            width: auto;
            height: auto;
            box-shadow: 0px 0px 10px #ddd;
        }
    }
    .more-views {
        width: 100%;

        li {
            position: relative;
            display: inline-block;
            margin-right: 5px;
            margin-bottom: 5px;
            height: 110px;
            width: 110px;
            overflow: hidden;

            &:nth-child(3n+3) {
                margin-right: 0;
            }
        }
        a.img-thumbnail {
            display: block;
            height: 100%;
            width: 100%;
            background-color: #fff;
        }
        img {
            position: absolute;  
            max-height: 100%;  
            max-width: 100%; 
            max-width: 100px;
            max-height: 100px;
            top: 0;  
            bottom: 0;  
            left: 0;  
            right: 0;  
            margin: auto;
        }
    }
}
.fancybox-nav {
    &.fancybox-next {
        right: -45px;
    }
    &.fancybox-prev {
        left: -45px;
    }
}

.product-desc {
    position: absolute;
    left: 380px;
}
@media only screen and (max-width: 780px) {
    .product-desc {
        position: relative;
        left: 0;
        width: 100%;
    }
    .product-img-box {
        width: 100%;
    }
}

.desc1 {
    display: block;

    h3 {
        text-shadow: 0 1px 0 #ffffff;
        color:#555555;
        font-size:2em;
        margin-bottom: 10px;
    }

    h5 {
        margin-top: 2%;
        font-size: 1.6em;
        color: #5EAFA5;
        line-height: 1.5em;
        text-shadow: 0 1px 0 #ffffff;

        a {
            margin-left:5px;
            color:#555555;
            text-transform:capitalize;
            font-size: 14px;

            &:hover {
                color: #5EAFA5;
            }
        }
    }

    p {
        font-size: 0.8725em;
        color: #555555;
        line-height: 1.8em;
    }
}

.available {
    margin-top: 10px;
    padding: 15px 0;
    border-top: 1px solid #EEEEEE;

    h4 {
        font-size: 1.1em;
        color: #777;
        margin-bottom: 20px;
        text-transform: uppercase;
        text-shadow: 0 1px 0 #ffffff;
    }

    .product-price {
        font-size: 1.6em;
        color: #439539;
        margin: 10px 0;
    }
}

.amount-label {
    margin-bottom: 10px;
    font-weight: bold;
}
.limit {
    margin-bottom: 10px;
}
.not-available {
    color: red;
    margin-bottom: 20px;
}

.order-by-email {
    color: #439539;
    margin-top: -10px;
    display: block;
}

.sellers {
    border: 1px solid rgb(235, 235, 235);

    h4 {
        font-size: 1.3em;
        color: #555;
        line-height: 1.5em;
        padding: 10px 20px;
        background: $drmax-darker-gray;
    }
}

.single-nav li {
    border-bottom: 1px dotted #E8E8E8;

    a {
        text-transform: capitalize;
        font-size: 0.8725em;
        color: #555555;
        padding: 15px 20px;
        display: block;

        &:hover {
            background: #FCFCFC;
            color: $drmax-green;
        }
    }
}