.header_bg{
    background: $drmax-green;
}

.header{
    padding: 1% 2%;
}

.header_btm {
    background: $drmax-purple;
    border-top: 1px solid #fff;
}
.header_sub {
    padding: 0 2%;

    .nav-list {
        margin-bottom: 10px;
    }
}

.logo{
    float: left;
    position: relative;
    height: 60px;

    a {
        color: #fff;
        text-decoration: none;
    }

    img {
        vertical-align: middle;
    }
}

.top-nav {
    display: none;
    position: relative;

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color:    #dedede;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:    #dedede;
        opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:    #dedede;
        opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color:    #dedede;
    }
}
.nav {
    width:100%;
}
.nav-list {
    display:none;
}
.nav-mobile {
    display:block;
    cursor: pointer;
    float:left;
    background: url(../images/nav_icon.png) 18% 52% no-repeat;
    height: 35px;
    width: 35px;
}
.nav-active, .nav-active-inner .nav-inner {
    display:block;
}

.h_menu {
    float: left;
    padding: 12px 0;
    color: #ffffff;
    font-size: 1.1em;

    ul li {
        position: relative;
        display: inline-block;
        border-right: 2px solid #fff;
        font-size: 0.9em;
        line-height: 0.9em;
        margin-right: 8px;
        padding-right: 8px;

        a {
            display: block;
            font-weight: bold;
            text-transform: uppercase;
            color: #ffffff;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }

        &.active a, a:hover {
            color: #f0f0f0;
        }

        &:last-child {
            padding-right: 0;
            margin-right: 0;
            border-right-width: 0;
        }
    }

    ul ul {
        display: none;
        position: absolute;
        top: 15px;
        left: -18px;
        padding-top: 15px;

        li {
            display: block;
            margin: 0;
            padding: 0;
            background-color: $drmax-purple;
            border-right-width: 0;
            z-index: 100;

            a {
                padding: 20px;
            }
        }
    }

    ul li:hover ul {
        display: block;
    }
}

.drmax-navigation {
    background-color: $drmax-gray;
    list-style-type: none;   

    li {
        border: 1px solid #efefef;

        a {
            display: block;
            padding: 12px;
            text-decoration: none;
            font-size: 0.9em;
            font-weight: bold;
        }

        &:hover {
            background-color: $drmax-darker-gray;
        }
    }
}

.nav-list.drmax-navigation {
    font-size: 1.5em;
}

.contact {
    float: right;
    position: relative;
    top: 15px;
    right: 112px;
    font-size: 2.2em;

    a {
        color: #fff;
    }
}

.user {
    float: right;
    position: relative;
    top: 19px;
    color: #fff;

    &:before {
        font-size: 38px;
    }

    .fa-user {
        font-size: 2.2em;
        line-height: 1.2em;
    }

    .user-options {
        position: absolute;
        right: 0;
        min-width: 170px;
        background-color: $drmax-gray;
        list-style-type: none;   
        z-index: 100;
        margin: 20px 0 0 0;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;
        box-shadow: 0px 2px 6px #777777;
        -webkit-box-shadow: 0px 2px 6px #777777;
        -moz-box-shadow: 0px 2px 6px #777777;
        -o-box-shadow: 0px 2px 6px #777777;
    }

    &:hover .user-options {
        margin: 0;
        opacity: 1;
        visibility: visible;
    }
}