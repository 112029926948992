// Large search box in the header
.h_search {
    float: right;
    position: relative;
    top: 20px;
    right: 20%;
    border: 1px solid rgb(216, 216, 216);
    width: 30.3333%;
    background: #fff;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;

    input[type="text"]{
        -webkit-appearance: none;
        padding: 6px 16px;
        outline: none;
        color: #312c3c;
        background: #FFFFFF;
        border: none;
        width: 84.33333%;
        line-height: 1.5em;
        position: relative;
        font-size: 0.8725em;
        text-transform: capitalize;
    }

    input[type="submit"]{
        -webkit-appearance: none;
        background: url('../images/search.png') no-repeat 10px 5px;
        padding: 6px 0px;
        border: none;
        cursor: pointer;
        width: 10.33%;
        line-height: 1.5em;
        outline: none;
        position: absolute;
        right: 0px;

        &:hover {
            background: url('../images/search_h.png') no-repeat 10px 5px;
        }
    }
}

.h_search.help {
    display: none;
    margin: 0;
    position: absolute;
    top: 2em;
    left: 0px;
    width: 100%;
    background-color: white;
    z-index: 100; 

    h3 {
        background-color: $drmax-darker-gray;
        padding: 8px;
    }

    .drmax-navigation a {
        padding: 5px 15px;
    }
}

// Search box for small devices
.search_box {
    position: absolute;
    right: 0;
    bottom: 0px;
    width: 30.3333%;
    margin-right: 3.333%;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;

    input[type="text"] {
        -webkit-appearance: none;
        padding: 6px 16px;
        outline: none;
        color: #ffffff;
        background: none;
        border: none;
        width: 74.33333%;
        line-height: 1.5em;
        position: relative;
        font-size: 0.8725em;
        text-transform: capitalize;
    }

    input[type="submit"] {
        -webkit-appearance: none;
        background: url('../images/search.png') no-repeat 10px 5px;
        padding: 6px 0px;
        border: none;
        cursor: pointer;
        width: 20.33%;
        line-height: 1.5em;
        outline: none;
        position: absolute;
        right: 0px;
    }
}
