/* Product View ========================================================================== */
.product-view .product-shop .availability { font-size:11px; }
.product-view .product-shop .availability span { font-weight:normal; }
.product-view { }
.product-essential {  }
.product-essential h2 { font:bold 13px/1.35 Arial, Helvetica, sans-serif; }
.product-collateral { background:#faf7ee url(../images/bkg_product_collateral.gif) 0 0 repeat-x; padding:25px; }
.product-collateral h2 { font-weight:bold; font-size:15px; color:#e26703; border-bottom:1px solid #e5dcc3; padding:0 0 1px; margin:0 0 15px; }
.product-collateral .box-collateral { margin:0 0 25px; }

/* Product Images */
.product-view .product-img-box { float:left; width:267px; }
.col3-layout .product-view .product-img-box { float:none; margin:0 auto; }
.product-view .product-img-box .product-image { margin:0 0 13px; }
.product-view .product-img-box .product-image-zoom { position:relative; width:265px; height:265px; overflow:hidden; z-index:9; }
.product-view .product-img-box .product-image-zoom img { position:absolute; left:0; top:0; cursor:move; }
.product-view .product-img-box .zoom-notice { font-size:11px; margin:0 0 5px; text-align:center; }
.product-view .product-img-box .zoom { position:relative; z-index:9; height:18px; margin:0 auto 13px; padding:0 28px; background:url(../images/slider_bg.gif) 50% 50% no-repeat; cursor:pointer; }
.product-view .product-img-box .zoom.disabled { -moz-opacity:.3; -webkit-opacity:.3; -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";/*IE8*/ opacity:.3; }
.product-view .product-img-box .zoom #track { position:relative; height:18px; }
.product-view .product-img-box .zoom #handle { position:absolute; left:0; top:-1px; width:9px; height:22px; background:url(../images/magnifier_handle.gif) 0 0 no-repeat;  }
.product-view .product-img-box .zoom .btn-zoom-out { position:absolute; left:2px; top:0; }
.product-view .product-img-box .zoom .btn-zoom-in { position:absolute; right:2px; top:0; }
.product-view .product-img-box .more-views h2 { font-size:11px; border-bottom:1px solid #ccc; margin:0 0 8px; text-transform:uppercase; }
.product-view .product-img-box .more-views ul { margin-left:-9px }
.product-view .product-img-box .more-views li { float:left; margin:0 0 8px 9px; }
.product-view .product-img-box .more-views li a { float:left; width:64px; height:85px; border:2px solid #ddd; overflow:hidden; }

.product-image-popup { margin:0 auto; }
.product-image-popup .buttons-set { float:right; clear:none; border:0; margin:0; padding:0; }
.product-image-popup .nav { font-weight:bold; margin:0 100px; text-align:center; }
.product-image-popup .image { display:block; margin:10px 0;  }
.product-image-popup .image-label { font-size:13px; font-weight:bold; margin:0 0 10px; color:#2f2f2f; }
/* Grouped Product */
.product-view .grouped-items-table .price-box { margin:0; padding:0; }

/* Block: Description */
.product-view .box-description {}

/* Block: Additional */
.product-view .box-additional .data-table th,
.product-view .box-additional .data-table td { line-height:1.25; }

/* Block: Upsell */
.product-view .box-up-sell h2 { border-bottom:0; padding:0; margin:0 0 8px; }
.product-view .box-up-sell .products-grid { width:100%; border:1px solid #e5dcc3; }
.product-view .box-up-sell .products-grid td { width:25%; background:#f6f2e7; border-right:1px solid #e5dcc3; border-bottom:1px solid #e5dcc3; padding:15px 10px 12px; line-height:1.6em; }
.product-view .box-up-sell .products-grid tr.last td { border-bottom:0; }
.product-view .box-up-sell .products-grid td.last { border-right:0; }
.product-view .box-up-sell .products-grid td img { border:1px solid #e5dcc3; }
.product-view .box-up-sell .products-grid .product-image { text-align:center; }
.product-view .box-up-sell .products-grid td.empty { border-right:0; background:#f1ecdb; }
.product-view .box-up-sell .products-grid .ratings .rating-box { float:none; display:block; margin:0 0 3px; }

/* Block: Tags */
.product-view .box-tags { margin:0; }
.product-view .box-tags h3 { font-size:13px; }
.product-view .box-tags .product-tags { display:block; margin:0 0 15px; }
.product-view .box-tags .product-tags li { display:inline; background:url(../images/bkg_pipe3.gif) 100% 4px no-repeat; padding:0 7px 0 4px; }
.product-view .box-tags .product-tags li.first { padding-left:0; }
.product-view .box-tags .product-tags li.last { background:none; padding-right:0; }
.product-view .box-tags .form-add label { display:block; font-size:13px; font-weight:bold; margin:0 0 5px; color:#0a263c;}
.product-view .box-tags .form-add .input-box { float:left; width:305px; margin:0 5px 0 0; background:url(../images/i_tag_add.gif) 0 2px no-repeat; padding:0 0 0 23px; }
.product-view .box-tags .form-add input.input-text { width:299px; }
.product-view .box-tags .form-add button.button span { border-color:#406a83; background:#618499; }
.product-view .box-tags .note { margin:3px 0 0; padding:0 0 0 23px; font-size:11px; }

/* Block: Reviews */
.product-view .box-reviews dl { margin:15px 0; }
.product-view .box-reviews dt a,
.product-view .box-reviews dt span { font-weight:bold; }
.product-view .box-reviews dd { margin:0 0 15px; }
.product-view .box-reviews dd small { font-style:italic; }
.product-view .box-reviews .form-add { margin:15px 0 0; }
.product-view .box-reviews .form-add h3 { font-size:13px; font-weight:normal; }
.product-view .box-reviews .form-add h3 span { font-weight:bold; }
.product-view .box-reviews .form-add h4 { font-size:12px; }
.product-view .box-reviews .form-add .data-table td { text-align:center; }
.product-view .box-reviews .form-add .form-list { margin:15px 0 0; }
.product-view .box-reviews .form-add .form-list .input-box { width:360px; }
.product-view .box-reviews .form-add .form-list input.input-text,
.product-view .box-reviews .form-add .form-list textarea { width:354px; }
/* Content Styles ================================================================= */
.product-name { margin:0; font-size:1em; font-weight:normal; }
.product-name a { color:#1e7ec8; }
/* Product Tags */
.tags-list { display:block; font-size:13px; border:1px solid #c1c4bc; background:#f8f7f5; padding:10px; }
.tags-list li { display:inline !important; margin:0 4px 0 0; }
.tags-list li a { color:#1b2d3b; }
/* My Tags */
.my-tag-edit { float:left; margin:0 0 10px; }
.my-tag-edit .btn-remove { float:right; margin:4px 0 0 5px; }
#my-tags-table { clear:both; }
#my-tags-table td { padding:10px; }
#my-tags-table .add-to-links { white-space:nowrap; }

/* My Reviews */
#my-reviews-table td { padding:10px; }
.product-review .product-img-box { float:left; width:140px;  }
.product-review .product-img-box .product-image { display:block; width:125px; height:125px; }
.product-review .product-img-box .label { font-size:11px; margin:0 0 3px; }
.product-review .product-img-box .ratings .rating-box { float:none; display:block; margin:0 0 3px; }
.product-review .product-details { margin-left:150px; }
.product-review .product-name { font-size:16px; font-weight:bold; margin:0 0 10px; }
.product-review h3 { font-size:12px; margin:0 0 3px; color:#2f2f2f; }
.product-review .ratings-table { margin:0 0 10px; }
.product-review dt { font-weight:bold; }
.product-review dd { font-size:13px; margin:5px 0 0; }
/* Clears ================================================================================ */
.clearer:after,
.header-container:after,
.header-container .top-container:after,
.header:after,
.header .quick-access:after,
#nav:after,
.main:after,
.footer:after,
.footer-container .bottom-container:after,
.col-main:after,
.col2-set:after,
.col3-set:after,
.col3-layout .product-options-bottom .price-box:after,
.col4-set:after,
.search-autocomplete li:after,
.block .block-content:after,
.block .actions:after,
.block li.item:after,
.block-poll li:after,
.block-layered-nav .currently li:after,
.page-title:after,
.products-grid:after,
.products-list li.item:after,
.box-account .box-head:after,
.dashboard .box .box-title:after,
.box-reviews li.item:after,
.box-tags li.item:after,
.pager:after,
.sorter:after,
.ratings:after,
.add-to-box:after,
.add-to-cart:after,
.product-essential:after,
.product-collateral:after,
.product-view .product-img-box .more-views ul:after,
.product-view .box-tags .form-add:after,
.product-view .product-shop .short-description:after,
.product-view .box-description:after,
.product-options .options-list li:after,
.product-options-bottom:after,
.product-review:after,
.cart:after,
.cart-collaterals:after,
.cart .crosssell li.item:after,
.opc .step-title:after,
.checkout-progress:after,
.multiple-checkout .place-order:after,
.group-select li:after,
.form-list li:after,
.form-list .field:after,
.buttons-set:after,
.page-print .print-head:after,
.advanced-search-summary:after,
.gift-messages-form .item:after,
.send-friend .form-list li p:after { display:block; content:"."; clear:both; font-size:0; line-height:0; height:0; overflow:hidden; }
/* ======================================================================================= */
.guest-select {width:305px !important;}
/*+++++++++++++++++++++++++++++++++++++*/
.products-grid .item {
    width: 200px !important;
    text-align: center !important;
    margin:4px;
}

.products-grid {
    background: none !important;
}
.products-grid .product-image {
    width: 200px !important;
    height: 250px !important;
}
.std ul {
    list-style: none outside none !important;
}
.products-grid {
    padding:0px !important;
    width:685px !important;
}
.products-grid .item .product-image {
    border: 1px solid #A0A0A0;
    background: #FFFFFF;
}
.products-list .product-image {
    width: 200px !important;
    height: 250px !important;
    border: 1px solid #A0A0A0;
    background: #FFFFFF;
}
.products-list .product-shop {
    margin-left: 220px;
}
/*---------------------------------------*/
/* CUSTOM */
.product-view .product-img-box {
    width: 100%;
}
.product-view .product-shop {
    width: 280px;
}

.product-img-box .product-image {

}
.more-views h2 {
    display: none;
}
.product-view .product-img-box .more-views li a {
    border: none;
}
.more-views {
    margin-right: 10px;
}
.product-view .product-img-box .more-views li {
    margin-bottom:33px !important;
}
/* product view gallery */
.img_play_btn {
    background: url("../../images/belvg/productviewgallery/plg_play.png") no-repeat scroll 0 0 transparent;
    cursor: pointer;
    height: 30px;
    width: 30px;
    z-index: 210;
    position: absolute;
}
.cs_disabled {
    opacity:0.3;
}
.cs_icon {
    margin:4px;
}
.cs_selected {
    background: #909090;
}
.cs_tooltip {
    background: url("../../images/belvg/productviewgallery/icon-tooltip.png") no-repeat scroll 0 0 transparent;
    cursor: help;
    display: inline-block;
    height: 15px;
    position: relative;
    vertical-align: middle;
    width: 15px;
    z-index: 1;
    margin-left:5px;
}
.cs_tooltip:hover {
    z-index: 100;
}
.cs_tooltip > span {
    display: none;
}
.cs_tooltip:hover > div:hover, .cs_tooltip > div {
    background: url("../../images/belvg/productviewgallery/bkg_tooltip.png") repeat-y scroll 100% center #F6F6F6;
    bottom: 28px;
    color: #464D50;
    left: -15px;   /**/
    opacity: 0;
    padding: 0 12px;
    position: absolute;
    visibility: hidden;
    width: 301px;
    z-index: 999;
}
.cs_tooltip > div:before {
    background: url("../../images/belvg/productviewgallery/bkg_tooltip.png") no-repeat scroll 0 0 transparent;
    content: "";
    height: 5px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: -5px;
    width: 325px; /**/
}
.cs_tooltip > div:after {
    background: url("../../images/belvg/productviewgallery/bkg_tooltip.png") no-repeat scroll -325px 0 transparent;
    bottom: -14px;
    content: "";
    height: 14px;
    left: -1px;
    overflow: hidden;
    position: absolute;
    width: 325px;  /**/
}
.cs_tooltip:hover > div:hover, .cs_tooltip > div {
}
.cs_tooltip:hover > div {
    bottom: 30px;
    opacity: 1;
    visibility: visible;
    width: 301px; /**/
}
.cs_tooltip:hover > div {
    bottom: 30px;
    opacity: 1;
    visibility: visible;
    width: 301px; /**/
}
.product-options-attr .selected {
}
.product-options-attr {
    clear:both;
}
.attr_icon div {
    padding:1px;
}
.attr_icon {
    border-color: -moz-use-text-color -moz-use-text-color #E0E0E0;
    border-image: none;
    border-style: none none solid;
    border-width: medium medium 1px;
    padding-bottom:10px;
}
.attr_icon .disabled {
    opacity:0.3;
}
#check {
    padding:1px;
}
#val_icon {
    float:left;
}
/*product list*/

.button-options {
    text-align: center;
    cursor: pointer;
}
.dropdown-options {
    z-index:120;
    position:absolute;
    /*padding:10px;*/
    background: none repeat scroll 0 0 #F8F7F5;
}
.dropdown-options .add-to-cart{
    margin:6px;
    align:center;
}
.product-addcart {
    padding:6px;
    padding-top:7px; 
    padding-bottom:4px;
    /*text-align: center;*/
}
.product-qty {
    padding:6px;
    padding-top:4px; 
    padding-bottom:4px;
    /*text-align: center;*/
}
.product-stock {
    padding:6px;
    padding-top:4px; 
    padding-bottom:7px;
    /*  text-align: center;  */
    border:none;
    border-bottom:1px solid #E0E0E0;   
}
.product-options-attr .attr_label {
    padding-top:6px;
    padding-bottom:4px;
}
.cs_product-options {
    margin:6px;  
}
.cs_option-reset {
    padding-top:10px !important;
    padding-bottom:10px !important;
    text-align:left;
    cursor:pointer;

}
.cs_option-reset span {
    background: none repeat scroll 0 0 #618499 !important;
    border-color: #406A83 !important;
}
/*-----------------------------------------------------------------------------*/
/*---------------------- Cloud-zoom -------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/* This is the moving lens square underneath the mouse pointer. */
.cloud-zoom-lens {
    border: 4px solid #898989;
    margin:-4px;	/* Set this to minus the border thickness. */
    background-color:#fff;	
    cursor:move;		
}
/* This is for the title text. */
.cloud-zoom-title {
    position:absolute !important;
    background-color:#000;
    color:#fff;
    padding:3px;
    width:100%;
    text-align:center;	
    font-weight:bold;
    font-size:10px;
    top:0px;
}
/* This is the zoom window. */
.cloud-zoom-big {
    border:4px solid #e0e0e0;
    overflow:hidden;
}
/* This is the loading message. */
.cloud-zoom-loading {
    color:white;	
    background:#222;
    padding:3px;
    border:1px solid #000;
    display:none;
}
/*-----------------------------------------------------------------------------*/
/*------------------------ Fancybox -------------------------------------------*/
/*-----------------------------------------------------------------------------*/
/*! fancyBox v2.1.0 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp
{
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top;
}
.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
}
.fancybox-skin {
    position: relative;
    background: #f9f9f9;
    color: #444;
    text-shadow: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
}
.fancybox-opened {
    z-index: 8030;
}
.fancybox-opened .fancybox-skin {
}
.fancybox-outer, .fancybox-inner {
    position: relative;
}
.fancybox-inner {
    overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
    -webkit-overflow-scrolling: touch;
}
.fancybox-error {
    color: #444;
    font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
    margin: 0;
    padding: 15px;
    white-space: nowrap;
}
.fancybox-image, .fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
}

.fancybox-image {
    max-width: 100%;
    max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background: url('../images/close.png') no-repeat;
}

#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
    background-position: 0 -108px;
    opacity: 0.8;
    cursor: pointer;
    z-index: 8060;
}
#fancybox-loading div {
    width: 44px;
    height: 44px;
    background: url('../../images/belvg/productviewgallery/fancybox/fancybox_loading.gif') center center no-repeat;
}
.fancybox-close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 8040;
}
.fancybox-nav {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    background: transparent url('../../images/belvg/productviewgallery/fancybox/blank.gif'); /* helps IE */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    z-index: 8040;
}
.fancybox-prev {
    left: 0;
}
.fancybox-next {
    right: 0;
}
.fancybox-nav span {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 34px;
    margin-top: -18px;
    cursor: pointer;
    z-index: 8040;
    visibility: hidden;
}

.fancybox-prev span {
    left: 10px;
    background-position: 0 -36px;
}

.fancybox-next span {
    right: 10px;
    background-position: 0 -72px;
}

.fancybox-nav:hover span {
    visibility: visible;
}
.fancybox-tmp {
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: hidden;
}
/* Overlay helper */
.fancybox-lock {
    overflow: hidden;
}
.fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: none;
    z-index: 8010;
    background: url('../../images/belvg/productviewgallery/fancybox/fancybox_overlay.png');
}
.fancybox-overlay-fixed {
    background: rgba(119, 119, 119, 0.93);
    position: fixed;
    bottom: 0;
    right: 0;
}
.fancybox-lock .fancybox-overlay {
    overflow: auto;
    overflow-y: scroll;
}
/* Title helper */
.fancybox-title {
    visibility: hidden;
    font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
    position: relative;
    text-shadow: none;
    z-index: 8050;
}
.fancybox-opened .fancybox-title {
    visibility: visible;
}
.fancybox-title-float-wrap {
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-bottom: -35px;
    z-index: 8050;
    text-align: center;
}
.fancybox-title-float-wrap .child {
    display: inline-block;
    margin-right: -100%;
    padding: 2px 20px;
    background: transparent; /* Fallback for web browsers that doesn't support RGBa */
    background: rgba(0, 0, 0, 0.8);
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -o-border-radius: 15px;
    border-radius: 15px;
    text-shadow: 0 1px 2px #222;
    color: #FFF;
    font-weight: bold;
    line-height: 24px;
    white-space: nowrap;
}
.fancybox-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff;
}
.fancybox-title-inside-wrap {
    padding-top: 10px;
}
.fancybox-title-over-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 10px;
    background: #000;
    background: rgba(0, 0, 0, .8);
}
.product-image{
    float:left;
    position:relative;
    width: 380px;
    height: 366px;
}
/*-----------------------------------------------------------------------------*/
/*------------------------ Fancybox Thumbs ------------------------------------*/
/*-----------------------------------------------------------------------------*/

#fancybox-thumbs {
    position: fixed;
    left: 0%;
    width: 100%;
    overflow: hidden;
    z-index: 8050;
}
#fancybox-thumbs.bottom {
    bottom: 2px;
}

#fancybox-thumbs.top {
    top: 2px;
}
#fancybox-thumbs ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
}
#fancybox-thumbs ul li {
    float: left;
    padding: 1px;
    opacity: 0.5;
}
#fancybox-thumbs ul li.active {
    opacity: 0.75;
    padding: 0;
    border: 1px solid #fff;
}
#fancybox-thumbs ul li:hover {
    opacity: 1;
}
#fancybox-thumbs ul li a {
    display: block;
    position: relative;
    overflow: hidden;
    border: 1px solid #aaa;
    background: #aaa;
    outline: none;
}
#fancybox-thumbs ul li img {
    display: block;
    position: relative;
    border: 0;
    padding: 0;
}
/*-----------------------------------------------------------------------------*/
/*------------------------ Fancybox Buttons -----------------------------------*/
/*-----------------------------------------------------------------------------*/
#fancybox-buttons {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 8050;
}
#fancybox-buttons.top {
    top: 10px;
}
#fancybox-buttons.bottom {
    bottom: 10px;
}
#fancybox-buttons ul {
    display: block;
    width: 166px;
    height: 30px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    border: 1px solid #111;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    background: rgb(50,50,50);
    background: -moz-linear-gradient(top, rgb(68,68,68) 0%, rgb(52,52,52) 50%, rgb(41,41,41) 50%, rgb(51,51,51) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(68,68,68)), color-stop(50%,rgb(52,52,52)), color-stop(50%,rgb(41,41,41)), color-stop(100%,rgb(51,51,51)));
    background: -webkit-linear-gradient(top, rgb(68,68,68) 0%,rgb(52,52,52) 50%,rgb(41,41,41) 50%,rgb(51,51,51) 100%);
    background: -o-linear-gradient(top, rgb(68,68,68) 0%,rgb(52,52,52) 50%,rgb(41,41,41) 50%,rgb(51,51,51) 100%);
    background: -ms-linear-gradient(top, rgb(68,68,68) 0%,rgb(52,52,52) 50%,rgb(41,41,41) 50%,rgb(51,51,51) 100%);
    background: linear-gradient(top, rgb(68,68,68) 0%,rgb(52,52,52) 50%,rgb(41,41,41) 50%,rgb(51,51,51) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#444444', endColorstr='#222222',GradientType=0 );
}
#fancybox-buttons ul li {
    float: left;
    margin: 0;
    padding: 0;
}
#fancybox-buttons a {
    display: block;
    width: 30px;
    height: 30px;
    text-indent: -9999px;
    background-image: url('../../images/belvg/productviewgallery/fancybox/fancybox_buttons.png');
    background-repeat: no-repeat;
    outline: none;
    opacity: 0.8;
}
#fancybox-buttons a:hover {
    opacity: 1;
}
#fancybox-buttons a.btnPrev {
    background-position: 5px 0;
}
#fancybox-buttons a.btnNext {
    background-position: -33px 0;
    border-right: 1px solid #3e3e3e;
}
#fancybox-buttons a.btnPlay {
    background-position: 0 -30px;
}
#fancybox-buttons a.btnPlayOn {
    background-position: -30px -30px;
}
#fancybox-buttons a.btnToggle {
    background-position: 3px -60px;
    border-left: 1px solid #111;
    border-right: 1px solid #3e3e3e;
    width: 35px
}
#fancybox-buttons a.btnToggleOn {
    background-position: -27px -60px;
}
#fancybox-buttons a.btnClose {
    border-left: 1px solid #111;
    width: 35px;
    background-position: -56px 0px;
}
#fancybox-buttons a.btnDisabled {
    opacity : 0.4;
    cursor: default;
}
/*----elScroll----*/
#cs_up {
    background: #bbbbbb url('../images/up_arrow.png') center no-repeat;
    width: 29px;
    height: 29px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -o-border-radius: 30px;
    margin-top: 4px;
}
#cs_down {
    background: #bbbbbb url('../images/down_arrow.png') center no-repeat;
    width: 29px;
    height: 29px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -o-border-radius: 30px;
    margin-top: -33px;
}
.product-view .product-img-box .more-views li {
    margin: 3px;
}
.product-view .product-img-box .product-image {
    overflow: visible;
    z-index: 10;
}
.product-img-box .product-image {
    background: url('../images/loading.gif') center no-repeat;
} 
@media only screen and (max-width: 1280px) {
    .wrap{
        width:95%;
    }
    .product-image {
        width: 350px;
    }
    .product-view .product-img-box .more-views li {
        margin-bottom: 15px !important;
    }
    #cs_down {
        margin-top: -15px;
    }
}
@media only screen and (max-width: 1024px) {
    .wrap{
        width:95%;
    }
    .product-image {
        width: 290px;
    }
    .product-view .product-img-box .more-views li {
        margin-bottom: 5px !important;
    }
    #cs_down {
        margin-top: -5px;
    }
    .btn_form {
        float: none;
    }
    .span_right {
        float: none;
    }
    .available span a {
        padding-top: 15px;
    }
}
@media only screen and (max-width: 800px) {
    .wrap{
        width:95%;
    }
}
@media only screen and (max-width: 640px) {
    .wrap{
        width:95%;
    }
    .product-image {
        width: 340px;
        height: 100%;
    }
}
@media only screen and (max-width: 480px) {
    .wrap{
        width:95%;
    }
    .product-image {
        width: 310px;
    }
    .product-essential {
        padding: 5px;
    }
}
@media only screen and (max-width: 320px) {
    .wrap{
        width:95%;
    }
    .product-essential {
        padding: 0px;
    }
    .product-image{
        float:left;
        position:relative;
        width:184px;
    }
    .product-view .product-img-box .more-views li {
        margin-bottom: 0px !important;
    }
}