.options {
    list-style-type: none;
    margin-bottom: 20px;

    li {
        display: inline-block;
    }
}

.orders-section {
    margin-bottom: 20px;

    .dataTables_wrapper {
        margin: 10px 0;
    }
}

.order-button {
    margin-bottom: 20px;
    margin-left: 10px;
    float: right;
    
    &.next-state {
        float: left;
        margin-left: 0;
        margin-right: 10px;
    }

    .btn {
        padding: 10px 12px;
        font-size: 1em;
    }
}

.dataTables_length {
    margin-top: 5px;
}

.orders-table {
    width: 100% !important;
}