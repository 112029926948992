$drmax-green: #54b948; 
$drmax-purple: #ed174f; 
$drmax-gray: #f0f0f0; 
$drmax-darker-gray: #c9cacc; 
$drmax-blue: #072b61; 

$brand-success: $drmax-green;
$brand-primary: $brand-success;

$fa-font-path: "../bower_components/components-font-awesome/fonts";
$icon-font-path: "../bower_components/bootstrap-sass/assets/fonts/bootstrap/";

$drmax-font-family: 'myriad-pro';
$drmax-font-family-bold: 'myriad-pro-bold';
$base-font-family: $drmax-font-family;
/*$base-font-family: 'bebas_neueregular';*/