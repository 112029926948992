.order-options {
    list-style-type: none;
    margin-bottom: 20px;

    li {
        display: inline-block;
        padding-left: 10px;
        border-left: 1px solid #98978B;
        margin-right: 10px;
        margin-bottom: 5px;

        &:first-child {
            padding-left: 0px;
            border-left-width: 0px;
        }
    }
}

.order-detail-info {
    margin: 20px 0;

    .order-detail-info-name {
        float: left;
        min-width: 115px;
        margin-right: 20px;
    }   
}

.pharmacy-info {
    width: 250px;
    margin-bottom: 20px;
    padding: 20px;
    background-color: $drmax-gray;
    border: 1px solid $drmax-darker-gray;
    border-radius: 5px;
}

table.order-detail-products {
    > tbody > tr > td {
        vertical-align: middle;

        &:first-child {
            width: 180px;
        }
    }

    img {
        display: block;
        max-width: 160px;
        max-height: 80px;
        width: auto;
        height: auto;
        box-shadow: 0px 0px 10px #ddd;
    }

    a {
        color: #3E3F3A;
        text-decoration: underline;
    }

    .counter {
        margin-bottom: 0;
    }

    .remove {
        display: inline-block;
        height: 24px;
        font-size: 20px;
        color: #555;
        line-height: 24px;
    }
    
    .product-col {
        text-align: left;
    }
    .amount-col, .price-col {
        width: 18%;
        text-align: right;
    }
    .reason-col {
        width: 20%;
        text-align: left;
    }
    
    small {
        font-size: 0.8em;
    }
    .total-price {
        font-size: 1.2em;
        font-weight: bold;
        text-align: right;
    }
}