html {
    position: relative;
    min-height: 100%;
    min-width: 520px;
}

body {
    font-family: $base-font-family;
    background:#ffffff;
    font-size: 100%;
    margin-bottom: 120px;
    padding-bottom: 20px;
}

.wrap{
    margin:0 auto;
    width:80%;
}

.main_bg1{
    border-top: 1px solid #fff;
    background: $drmax-darker-gray;
}
.main1{
    padding: 1% 2%;
}
.main1 h2{
    font-size: 1.3em;
    color:#555;
}
.main1 h1 {
    text-align: center;
    font-size: 40px;
    color: #fff;
    text-shadow: 0 1px 0 rgb(24, 141, 118);
}
.page_title_bg {
    border-top: 1px solid #fff;
    background: $drmax-purple;
}

.main_bg{
    background: #ffffff;
}
.main{
    padding: 1% 2%;
}

.footer_bg1 {
    position: absolute;
    bottom: 0;
    height: 120px;
    width: 100%;
    background: #ffffff url("../images/footer_hatch.png") 0 0 repeat-x;

    #cross {
        position: absolute;
        right: 17px;
        top: -17px;
        width: 34px;
        height: 34px;
        background: url(../images/krizek.png) no-repeat right top;
    }
}
.footer_bg {
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 100%;
    background: $drmax-purple;	
}
#back-top {
    display: none;
    position: fixed;
    bottom: 30px;
    right: 17px;
    z-index: 100;

    a {
        display: block;
        width: 34px;
        height: 34px;
        border: none;
        text-indent: 100%;
        background: url(../images/krizek.png) no-repeat right top;
    }
}

.copy {
    text-align:center;

    p {
        font-size: 0.9em;
        line-height: 30px;
        color: #ffffff;
    }

    a {
        color:#fefefe;
        text-decoration: underline;

        &:hover{
            text-decoration: none;
        }
    }
}

h2.style{
    font-size: 2em;
    color: #777777;
    text-shadow: 0 1px 0 #ffffff;
}
h2.top{
    margin-top: 2%;
}

.contact-section {
    margin-bottom: 20px;

    p {
        padding: 10px 10px 0;
    }
}

.note {
    font-style: italic;
}
