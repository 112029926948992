.breadcrumb {
    margin-bottom: 0;
    border: none;
    background-color: transparent;
    margin: 1% 2% 0px;
    padding: 0 0 5px 0;
    font-size: 1em;
    border-bottom: 1px solid #efefef;
    
    a {
        text-decoration: underline;
    }
}