/* Variables */
@import "variables";

/* Bootstrap */
@import "themes/sandstone/variables";
@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "themes/sandstone/bootswatch";

/* Font Awesome */
@import "../bower_components/components-font-awesome/scss/font-awesome";

/* Select2 */
@import "../bower_components/select2/src/scss/core";

/* eonasdan-bootstrap-datetimepicker */
@import "../bower_components/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build";

/* Font */
@import "fonts";

@import "reset";
@import "layout";
@import "sites/signIn";
@import "sites/product-detail";
@import "sites/product-overview";
@import "sites/order-detail";
@import "sites/orders-admin";
@import "sites/stats";
@import "sites/limits";

@import "partials/header";
@import "partials/carousel";
@import "partials/productviewgallery";
@import "partials/slider";
@import "partials/cart";
@import "partials/breadcrumbs";
@import "partials/alerts";
@import "partials/search-box";
@import "partials/forms";
@import "partials/modals";
@import "partials/tabs";

/*
Author: W3layout
Author URL: http://w3layouts.com
License: Creative Commons Attribution 3.0 Unported
License URL: http://creativecommons.org/licenses/by/3.0/
*/
.btn {
    font-size: 1.1em;
    letter-spacing: 0.5pt;
}
#ajax-spinner {
    position:fixed;
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
    background: white url('../images/spinner.gif') no-repeat 50% 50%;
    padding: 13px;
    border: 2px solid #CCC;
    font-size: 0;
}

h3.section-headline {
    margin-bottom: 10px;
    padding: 10px 10px;
    background-color: $drmax-darker-gray;
    color:#555;
    font-size: 1.5em;
}

/* start cauersol */
.cau_left{
    float:left;
    width: 47.3333%;
    margin-left: 3.33333%;
}
.cau_left:first-child{
    margin-left: 0%;
}

.clearfix:before,
.clearfix:after,
.row:before,
.row:after {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0; }
.row:after,
.clearfix:after {
    clear: both; }
.row,
.clearfix {
    zoom: 1; }
.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}
/* end gallery_sale */
/* start handbags */


/* start contact */
.map{
    margin: 2% 2% 2% 0;
}
.col{
    display: block;
}
.contact-form{
    position:relative;
}
.contact-form div{
    padding:5px 0;
}
.contact-form span label{
    font-family: 'Source Sans Pro', sans-serif;
    color: #777777;
    display: block;
    font-size: 0.8725em;
    padding-bottom: 5px;
    text-transform: capitalize;
}
.contact-form input[type="text"], .contact-form textarea {
    font-family: 'Source Sans Pro', sans-serif;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    color: rgba(85, 81, 81, 0.84);
    padding: 8px;
    display: block;
    width: 96.3333%;
    outline: none;
    -webkit-appearance: none;
    text-transform: capitalize;
}
.contact-form textarea{
    resize:none;
    height:120px;		
}
.contact-form input[type="submit"]{
    margin-top:10px;
    font-family: 'Source Sans Pro', sans-serif;
    -webkit-appearance: none;
    cursor:pointer;
    color: #ffffff;
    background: #b10000;
    border:none;
    outline:none;
    display: inline-block;
    text-transform: uppercase;
    padding: 12px 38px;
    font-size: 0.875em;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.contact-form input[type="submit"]:hover{
    background: #555555;
    color: #ffffff;
}
/* start share-des */
.share-desc{
    padding: 20px 0;
}
.share h4{
    font-size: 1.1em;
    color: #777;
    margin-bottom: 10px;
    text-transform: uppercase;
    text-shadow: 0 1px 0 #ffffff;
}
.share_nav{
    float:left;
}
.share_nav li {
    display: inline-block;
    margin: 5px 6px;
}
.share_nav li a {
    background: #9C9C9C;
    padding: 10px;
    display: block;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -o-border-radius: 30px;
}
.share_nav li a:hover{
    background: #b10000;
}
.share li img {
    vertical-align:middle; 
}


/* end tabs */
/***** Media Quries *****/
@media only screen and (max-width: 1420px) {
    .wrap{
        width:95%;
    }
    .span1_of_1 {
        width: 52.3333%;
    }
    .span1_of_1_des {
        width: 42.3333%;
        float: left;
    }
}
@media only screen and (max-width: 1280px) {
    .wrap{
        width:95%;
    }
    .desc1 h3 {
        font-size: 1.4em;
    }
    .product-image {
        width: 350px;
    }
    .h_search  input[type="submit"] {
        width: 13.33%;
    }
}
@media only screen and (max-width: 1024px) {
    .wrap{
        width:95%;
    }
    .h_search input[type="submit"] {
        width: 10.33%;
    }
    .grid1_of_3 h3 {
        font-size: 1.5em;
    }
    .span1_of_1 {
        width: 56.3333%;
    }
    .span1_of_1_des {
        width: 38.3333%;
    }
    .single-nav li a {
        padding: 10px 10px;
    }
    .h_search  input[type="submit"] {
        width: 17.33%;
    }
}
@media only screen and (max-width: 900px) {
    .h_search {
        right: 13%;
    }
}
@media only screen and (max-width: 800px) {
    .wrap{
        width:95%;
    }
    .h_search {
        display: none;
    }
    .h_menu {
        display: none;
    }
    .header_sub {
        padding: 2% 2%;
    }
    .top-nav {
        display: block;
    }
    .ser-main {
        float: none;
        width: 100%;
        margin-right: 0;
    }
    .ser-grid-list h5 a {
        font-size: 1.5em;
    }
    .grid1_of_4 {
        float: left;
        width: 45.333%;
        margin-top: 2.3333%;
    }
    .grid1_of_4:nth-child(3){
        margin-left: 0;
    }
    .span1_of_1 {
        width: 54.3333%;
    }
    .span1_of_1_des {
        width: 42.3333%;
    }
    .tabs input {
        width: 226px;
    }
    .tabs input#tab-2 {
        left: 220px;
    }
    .tabs input#tab-3 {
        left: 444px;
    }
    .desc1 h5 {
        font-size: 1.4em;
    }
    .btn_form {
        margin-top: 4%;
    }
}
@media only screen and (max-width: 640px){
    .wrap{
        width:95%;
    }
    .search_box {
        margin-right: 0;
    }
    #filters li span {
        padding: 10px 20px;
    }
    .buy {
        font-size: 1.2em;
    }
    .span1_of_1 {
        float:none;
        width: 100%;
    }
    .span1_of_1_des {
        margin-top:4%;
        float:none;
        width: 100%;
        margin-left: 0;
    }
    .tabs input {
        width: 75px;
    }
    .tabs label {
        width: 18.3333%;
    }
}
@media only screen and (max-width: 480px) {
    .wrap{
        width:95%;
    }
    .search_box {
        width: 44.3333%;
    }
    .grid1_of_3 {
        float: none;
        width: 99.333%;
        margin-left: 0%;
        margin-bottom: 4%;
    }
    .grid1_of_4 {
        margin-left:0;
        float: none;
        width: 99.333%;
    }
    .ser-grid-list {
        float: none;
        width: 100%;
        margin-left: 0%;
        margin-bottom: 4%;
    }
    .ser-grid-list h5 {
        margin: 2% 0 1%;
    }
    .tabs input {
        width: 99%;
    }
    .tabs label {
        width: 100%;
        float:none;
        padding: 0;
    }
    .tabs input#tab-2 {
        left: 0px;
    }
    .tabs input#tab-3 {
        left: 0px;
    }
    .tabs input#tab-4 {
        left: 0px;
    }
    .content div {
        width: 94%;
    }
    .product-essential {
        padding: 5px;
    }
}
@media only screen and (max-width: 320px) {
    .wrap{
        width:95%;
    }
    .search_box {
        width: 60.3333%;
    }
    .product-essential {
        padding: 0px;
    }
    .tabs {
        margin: 10px auto;
    }
}